import { Component, OnInit , Renderer2 } from '@angular/core';
import { Router } from '@angular/router';
import { NgbCalendar, NgbDateStruct, NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { AddUpdateAddressComponent } from "../../modals/add-update-address/add-update-address.component";
import { APIService } from "../../services/api.service";
import { UtilService } from "../../services/util.service";
import { OrderpopupComponent } from "../orderpopup/orderpopup.component";
import { PhoneverficationComponent } from "../auth/phoneverfication/phoneverfication.component";
import { ToastrService } from "ngx-toastr";
import { EmitEvent, Events, EventService } from "../../services/event.service";
import { ConfirmationModalComponent } from "../../modals/confirmation-modal/confirmation-modal.component";
import { Meta, Title } from '@angular/platform-browser';
import { InvoiceService } from '../../services/invoice.service';
import { OrderIdService } from '../../services/order-id.service';

declare var google: any;
declare var Razorpay: any;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})
export class CheckoutComponent implements OnInit {
  profile_name;
  model: NgbDateStruct;
  date: { year: number, month: number };
  step: number = 1;
  userAddresses = [];
  cartItems = [];
  totalPrice = 0;
  chefPrice = 0;
  chefAddress;
  deliveryCharges = 0;
  deliveryServiceCharges = 0;
  totalTax = 0;
  cpkm = 0;
  base_delivery_charge = 0;
  cooked_gst = 0;
  delivery_charges_gst = 0;
  packaged_gst = 0;
  distance = 0;
  deliveryDT = '';
  comment = '';
  splitGST = false;
  commission = 0;
  chefId=''
  chef_special_cuisine = '';
  cuisines: any = [];
  coupon;
  // @ViewChild('gmap', {static: false}) gmapElement: ElementRef;
  // map: any;
showDate;
  marker;
  markerInfo;
  chefMarker;
  chefInfo;
  minDate;
startDate;
min_order_val;
  btn_9_en = true;
  btn_12_en = true;
  btn_3_en = true;
  btn_6_en = true;
  btn_7_en = true;
  btn_4_en= true;
  btn_4_3_en= true;
  btn_5_en= true;
  btn_5_3_en= true;
  btn_6_3_en = true
  btn_07_en= true;
  btn_7_3_en = true;
  btn_8_en = true;
  btn_13_en = true;
  btn_14_en = true;
  btn_15_en = true;
  btn_16_en = true;
  btn_17_en = true;
  btn_18_en = true;
  btn_19_en = true;
  btn_20_en = true;
  btn_21_en = true;
  btn_22_en = true;

  activated_btn = "activatedBtn";
  notactivedBtn = "notactivedBtn";

  btn_11_class = this.notactivedBtn;
  btn_18_class = this.notactivedBtn;
  btn_7_class = this.notactivedBtn;
  btn_3_class= this.notactivedBtn;
  btn_4_class = this.notactivedBtn;
  btn_4_3_class = this.notactivedBtn;
  btn_5_class = this.notactivedBtn;
  btn_5_3_class = this.notactivedBtn;
  btn_6_class = this.notactivedBtn;
  btn_6_3_class = this.notactivedBtn;
  btn_07_class = this.notactivedBtn;
  btn_7_3_class = this.notactivedBtn;
  btn_8_class = this.notactivedBtn
  btn_13_class = this.notactivedBtn
  btn_14_class = this.notactivedBtn
  btn_15_class = this.notactivedBtn
  btn_16_class = this.notactivedBtn
  btn_17_class = this.notactivedBtn
  btn_018_class = this.notactivedBtn
  btn_19_class = this.notactivedBtn
  btn_20_class = this.notactivedBtn
  btn_21_class = this.notactivedBtn
  btn_22_class = this.notactivedBtn

  // TEST_RZP_KEY = "rzp_test_8UJMZg4w1Llhhc";
  TEST_RZP_KEY = "rzp_test_LDC50yRioTgPPK";
  LIVE_RZP_KEY = "rzp_live_hVMbSAiMoEhCTe";

  RZP_KEY = this.TEST_RZP_KEY;
  // RZP_KEY = this.LIVE_RZP_KEY;

  eventDetail = null;
addDisabled=false;
addPaymentDisabled=false;
availableSlots: string[] = [];
  currentHour: number = new Date().getHours();
viewMore = false;
timeslot;
couponValue;
message;
couponData;
couponApplied: boolean = false;
discountedTotalAmount: number = 0;
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private apiService: APIService,
    public utilService: UtilService,
    private calendar: NgbCalendar,
    private toaster: ToastrService,
    private eventService: EventService,
    private title: Title,
    private meta: Meta,
    private invoiceService: InvoiceService,
    private orderService: OrderIdService,
    private renderer: Renderer2
  ) {
    let current = new Date();
    this.minDate = {
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    };
    this.startDate ={
      year: current.getFullYear(),
      month: current.getMonth() + 1,
      day: current.getDate()
    }
  }

  getTime(text,slot) {
    console.log(text,"time");
    let selectedTime = this.model.year + "-" + this.model.month + "-" + this.model.day + " " + text + ":00:00";
    console.log('selected time:-' + selectedTime);
    this.resetButtonClasses();
    this.deliveryDT = selectedTime;
    if (text == '11') {
      this.btn_11_class = this.activated_btn;
    } else {
      this.btn_11_class = this.notactivedBtn;
    }
    if (text == '18') {
      this.btn_18_class = this.activated_btn;
    } else {
      this.btn_18_class = this.notactivedBtn;
    }
    // if (text == '19') {
    //   this.btn_7_class = this.activated_btn;
    // } else {
    //   this.btn_7_class = this.notactivedBtn;
    // }
    if (text == '13') {
      this.btn_20_class = this.activated_btn;
    } else {
      // this.btn_21_class = this.notactivedBtn;
      // this.btn_22_class = this.notactivedBtn;
      this.btn_20_class = this.notactivedBtn;
    }
    // if (text == '17') {
    //   this.btn_5_class = this.activated_btn;
    // } else {
    //   this.btn_5_class = this.notactivedBtn;
    // }
    // if (text == '18') {
    //   this.btn_6_class = this.activated_btn;
    // } else {
    //   this.btn_6_class = this.notactivedBtn;
    // }
    if (text == '15') {
      this.btn_21_class = this.activated_btn;
    } else {
      // this.btn_22_class = this.notactivedBtn;
      // this.btn_20_class = this.notactivedBtn;
      this.btn_21_class = this.notactivedBtn;
    }
    if (text == '19') {
      this.btn_22_class = this.activated_btn;
    } else {
      // this.btn_21_class = this.notactivedBtn;
      // this.btn_20_class = this.notactivedBtn;
      this.btn_22_class = this.notactivedBtn;
    }
    // if (text == '14') {
    //   this.btn_13_class = this.activated_btn;
    // } else {
    //   this.btn_13_class = this.notactivedBtn;
    // }
    // if (text == '15') {
    //   this.btn_14_class = this.activated_btn;
    // } else {
    //   this.btn_14_class = this.notactivedBtn;
    // }
    // if (text == '16') {
    //   this.btn_15_class = this.activated_btn;
    // } else {
    //   this.btn_15_class = this.notactivedBtn;
    // }
    // if (text == '17') {
    //   this.btn_16_class = this.activated_btn;
    // } else {
    //   this.btn_16_class = this.notactivedBtn;
    // }
    // if (text == '18') {
    //   this.btn_17_class = this.activated_btn;
    // } else {
    //   this.btn_17_class = this.notactivedBtn;
    // }
    // if (text == '19') {
    //   this.btn_018_class = this.activated_btn;
    // } else {
    //   this.btn_018_class = this.notactivedBtn;
    // }
    // if (text == '20') {
    //   this.btn_19_class = this.activated_btn;
    // } else {
    //   this.btn_19_class = this.notactivedBtn;
    // }
    // if (text == '20') {
    //   this.btn_19_class = this.activated_btn;
    // } else {
    //   this.btn_19_class = this.notactivedBtn;
    // }
    if(this.deliveryDT != '' && this.getSelectedUserAddress() != null){
      this.getPaymentDisable(true)
      // this.addPaymentDisabled= true
    }
    // let timeslot = '';
    switch (slot) {
        case '1':
          this.timeslot = '11AM - 2PM';
            break;
        case '2':
            this.timeslot = '6PM - 9PM';
            break;
        case '3':
            this.timeslot = '7PM - 8PM'; // Assuming same day delivery time
            break;
        case '4':
            this.timeslot = '4PM'; 
            break;
        case '5':
            this.timeslot = '5PM'; 
            break;
        case '6':
            this.timeslot = '6PM'; 
            break;
        case '7':
            this.timeslot = '7PM'; 
            break;
            case '8':
            this.timeslot = '8PM'; 
            break;
            case '9':
            this.timeslot = '1PM - 2PM'; 
            break;
            case '10':
            this.timeslot = '2PM - 3PM'; 
            break;
            case '11':
            this.timeslot = '3PM - 4PM'; 
            break;
            case '12':
            this.timeslot = '4PM - 5PM'; 
            break;
            case '13':
            this.timeslot = '5PM - 6PM'; 
            break;
            case '14':
            this.timeslot = '6PM - 7PM'; 
            break;
            case '15':
            this.timeslot = '7PM - 8PM'; 
            break;
            case '16':
            this.timeslot = '1PM - 4PM'; 
            break;
            case '17':
            this.timeslot = '4PM - 7PM'; 
            break;
            case '18':
            this.timeslot = '8PM - 11PM'; 
            break;
        default:
            this.timeslot = ''; // Handle other cases if needed
            break;
    }
    console.log('Selected time slot:', this.timeslot); 
  }
  getDTime(text) {
    console.log(text,"time");
    let selectedTime = this.model.year + "-" + this.model.month + "-" + this.model.day + " " + text + ":00";
    console.log('selected time:-' + selectedTime);
  this.resetButtonClasses();
    this.deliveryDT = selectedTime;
    if (text == '15:30') {
      this.btn_3_class = this.activated_btn;
    } else {
      this.btn_3_class = this.notactivedBtn;
    }
    if (text == '16:30') {
      this.btn_4_3_class = this.activated_btn;
    } else {
      this.btn_4_3_class = this.notactivedBtn;
    }
    if (text == '17:30') {
      this.btn_5_3_class = this.activated_btn;
    } else {
      this.btn_5_3_class = this.notactivedBtn;
    }
    if (text == '18:30') {
      this.btn_6_3_class = this.activated_btn;
    } else {
      this.btn_6_3_class = this.notactivedBtn;
    }
    if (text == '19:30') {
      this.btn_7_3_class = this.activated_btn;
    } else {
      this.btn_7_3_class = this.notactivedBtn;
    }
    if(this.deliveryDT != '' && this.getSelectedUserAddress() != null){
      this.getPaymentDisable(true)
      // this.addPaymentDisabled= true
    } 
  }
  resetButtonClasses() {
    // Reset all button classes to not active
    this.btn_11_class = this.notactivedBtn;
    this.btn_18_class = this.notactivedBtn;
    this.btn_7_class = this.notactivedBtn;
    this.btn_4_class = this.notactivedBtn;
    this.btn_5_class = this.notactivedBtn;
    this.btn_6_class = this.notactivedBtn;
    this.btn_07_class = this.notactivedBtn;
    this.btn_8_class = this.notactivedBtn;
    this.btn_3_class = this.notactivedBtn;
    this.btn_4_3_class = this.notactivedBtn;
    this.btn_5_3_class = this.notactivedBtn;
    this.btn_6_3_class = this.notactivedBtn;
    this.btn_7_3_class = this.notactivedBtn;
    this.btn_13_class = this.notactivedBtn
    this.btn_14_class = this.notactivedBtn
    this.btn_15_class = this.notactivedBtn
    this.btn_16_class = this.notactivedBtn
    this.btn_17_class = this.notactivedBtn
    this.btn_018_class = this.notactivedBtn
    this.btn_19_class = this.notactivedBtn
    this.btn_20_class = this.notactivedBtn
    this.btn_21_class = this.notactivedBtn
    this.btn_22_class = this.notactivedBtn
}
  setMetaTags() {
    this.title.setTitle('order online home-cooked food from the best chefs');
    this.meta.addTag({
      name: 'description',
      content: 'order online home-cooked food directly from the chefs kitchen to your dining table'
    });
    this.meta.addTag({ name: 'keywords', content: "Order delicious food, online food" })
    const canonicalURL = `https://www.wethechefs.in/checkout`; 
  
    this.meta.addTag({ rel: 'canonical', href: canonicalURL });
    this.meta.addTag({ rel: 'alternate', hreflang: 'en', href: canonicalURL });
  }
 
  ngOnInit(): void {
    this.setMetaTags();
    if (!this.utilService.isUserLoggedIn()) {
      this.router.navigateByUrl('/home')
    }
    this.apiService.postAPI(this.apiService.BASE_URL + "user/getChefFullDetailByChefId", {
      chef_id: localStorage.getItem('chef_id_check'),
    }).then((result) => {
      if (result.status) {
        this.commission = result.result.profile.commission;
        console.log(this.commission,"commission 266")
        this.reload();
        this.eventService.on(Events.CART_ITEM_UPDATE, (data => {
          this.getUserCartItems()
        }));
      }
    })
   
this.getUserProfile();
   this.getAllCoupon(); 
    //
    // setTimeout(() => {
    //   this.downloadInvoice(116);
    // }, 1000);
   this.calculateAvailableSlots();
   this.addFacebookPixelScript();
  
  }
  datePicker:any
  
  calculateAvailableSlots() {
    // Clear existing slots
    this.availableSlots = [];

    // Define business rules
    const maxHoursAhead = 3;
    const closingTime = 17; // 5 PM in 24-hour format

    // Calculate available slots based on current time
    if (this.currentHour < closingTime) {
      // If current time is before closing time, show slots for today
      for (let hour = this.currentHour + 1; hour <= closingTime; hour++) {
        for (let minute of ['00', '30']) {
          this.availableSlots.push(`${hour}:${minute}`);
        }
      }
    } else {
      // If it's past closing time, show slots for tomorrow
      const nextDayClosingTime = closingTime + 24; // Add 24 hours for next day
      for (let hour = 0; hour <= nextDayClosingTime - maxHoursAhead; hour++) {
        for (let minute of ['00', '30']) {
          this.availableSlots.push(`${hour}:${minute}`);
        }
      }
    }
  }
  private addFacebookPixelScript(): void {
    const script = this.renderer.createElement('script');
    script.type = 'text/javascript';
    script.text = `
      !(function (f, b, e, v, n, t, s) {
        if (f.fbq) return;
        n = f.fbq = function () {
          n.callMethod
            ? n.callMethod.apply(n, arguments)
            : n.queue.push(arguments);
        };
        if (!f._fbq) f._fbq = n;
        n.push = n;
        n.loaded = !0;
        n.version = "2.0";
        n.queue = [];
        t = b.createElement(e);
        t.async = !0;
        t.src = v;
        s = b.getElementsByTagName(e)[0];
        s.parentNode.insertBefore(t, s);
      })(
        window,
        document,
        "script",
        "https://connect.facebook.net/en_US/fbevents.js"
      );
      fbq("init", "392739869127648");
      fbq("track", "PageView");
    `;
    this.renderer.appendChild(document.head, script);
  }
  getTimeD(slot: string) {
    // Handle slot selection
    console.log('Selected time:', slot);
  }
getUserProfile(){
  let profile = this.utilService.getUserProfile();
  if (profile != null) {
    this.profile_name = profile.name;

  }
}

  reload(){
    this.datePicker = JSON.parse(localStorage.getItem('Date_check'))
    let currentPastDate = new Date();
    let currentPastDateCheck = new Date(this.datePicker);
    // console.log(currentPastDateCheck,"currentPast date");
    
    // if(localStorage.getItem("chef_id_check")=="12"){
      if(localStorage.getItem("same_day")=='12'){
      currentPastDate.setDate(currentPastDate.getDate());
      console.log("462")
    }
    else if(localStorage.getItem("same_day")=='48'){
      console.log("466")
      currentPastDate.setDate(currentPastDate.getDate() + 2);
    }
    else if(localStorage.getItem("same_day")=='72'){
      console.log("470")
      currentPastDate.setDate(currentPastDate.getDate() + 3);
    }
    else {
      console.log("474")
    currentPastDate.setDate(currentPastDate.getDate() + 1);
    // currentPastDate.setDate(currentPastDate.getDate() + 1);
  
    }
  this.minDate = {
    year: currentPastDate.getFullYear(),
    month: currentPastDate.getMonth() + 1,
    day: currentPastDate.getDate()
  }
  this.startDate = {
    year: currentPastDateCheck.getFullYear(),
    month: currentPastDateCheck.getMonth() + 1,
    day: currentPastDateCheck.getDate()
  }
  // console.log("mindate:-" + JSON.stringify(this.minDate));

  this.model = this.startDate;
  this.onDateSelected()
}

  onDateSelected() {
    if(localStorage.getItem('chef_id_check') =='732'){
      console.log(localStorage.getItem('chef_id_check'),"493")
    // console.log("modal:-" + JSON.stringify(this.model));
    let currentDate = new Date();
    let dt_09 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T11:00:00";
    // let dt_12 = this.model.year + "-" + this.model.month + "-" + this.model.day + " 12:00:00";
    // let dt_03 = this.model.year + "-" + this.model.month + "-" + this.model.day + " 15:00:00";
    let dt_06 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T18:00:00";
    let dt_07 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T19:00:00";
    let dt_03 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T15:30:00";
    // let dt_03_30 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T15:30:00";
    let dt_4 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T16:00:00";
    let dt_4_30 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T16:30:00";
    let dt_5 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T17:00:00";
    let dt_5_30 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T17:30:00";
    let dt_6 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T18:00:00";
    let dt6_30 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T18:30:00";
    let dt_007 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T19:00:00";
    let dt_07_30 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T19:30:00";
    let dt_08 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T20:00:00";
    
  
    if (this.utilService.getDifferenceInHours(new Date(dt_09), currentDate) >= 24) {
      this.btn_9_en = true;
    } else {
      this.btn_9_en = false;
      this.btn_11_class = this.notactivedBtn;
    }
    if (this.utilService.getDifferenceInHours(new Date(dt_06), currentDate) >= 24) {
      this.btn_12_en = true;
    } else {
      this.btn_12_en = false;
      this.btn_18_class = this.notactivedBtn;
    }

   if ((this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) >= 7)) {
      this.btn_7_en = true;
    } else {
      this.btn_7_en= false;
        this.btn_7_class = this.notactivedBtn;
    }

    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 7.99)) {
      this.btn_3_en = true;
    } else {
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        this.btn_3_en = false;
        this.btn_3_class = this.notactivedBtn
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 6)) {
      // if ((this.utilService.getDifferenceInHours(new Date(dt_4), currentDate) >= 2.99)) {
      this.btn_4_en = true;
    } else {
      console.log(this.utilService.getDifferenceInHours(new Date(dt_08), currentDate),"393")
      this.btn_4_en= false;
      this.btn_4_class = this.notactivedBtn;
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 6.9)) {
      this.btn_4_3_en = true;
    } else {
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        this.btn_4_3_en= false;
        this.btn_4_3_class = this.notactivedBtn;
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 6)) {
      this.btn_5_en = true;
    } else {
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        this.btn_5_en = false
        this.btn_5_class= this.notactivedBtn
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 5.9)) {
      this.btn_5_3_en = true;
    } else {
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        this.btn_5_3_en = false;
        this.btn_5_3_class = this.notactivedBtn
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 5)) {
      this.btn_6_en = true;
    } else {
      this.btn_6_en = false;
      this.btn_6_class = this.notactivedBtn
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 4.9)) {
      this.btn_6_3_en = true;
    } else {
      this.btn_6_3_en = false;
      this.btn_6_3_class = this.notactivedBtn
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 4)) {
      this.btn_07_en = true;
    } else {
      this.btn_07_en = false;
      this.btn_07_class = this.notactivedBtn;
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) { 
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 3.51)) {
      this.btn_7_3_en = true;
    } else {
      this.btn_7_3_en = false;
      this.btn_7_3_class = this.notactivedBtn;
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 3)) {
      this.btn_8_en = true;
    } else {
      this.btn_8_en= false;
      this.btn_8_class = this.notactivedBtn;
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        let nextDate = new Date(this.model.year, this.model.month - 1, this.model.day + 1);
        console.log(nextDate,"477");
      
        this.model = {
            year: nextDate.getFullYear(),
            month: nextDate.getMonth() + 1,
            day: nextDate.getDate()
        }; 
    }
    if (!this.btn_12_en && !this.btn_9_en && !this.btn_7_en && !this.btn_3_en) {
      this.deliveryDT = '';
    }
 this.formatDate(dt_09)
  }
  else if(localStorage.getItem('chef_id_check') =='549'){
    console.log(localStorage.getItem('chef_id_check'),"621")
    // console.log("modal:-" + JSON.stringify(this.model));
    let currentDate = new Date();
    let dt_09 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T11:00:00";
    let dt_06 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T18:00:00";
    let dt_07 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T19:00:00";
    let dt_08 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T20:00:00";
  
    if (this.utilService.getDifferenceInHours(new Date(dt_09), currentDate) >= 24) {
      this.btn_9_en = true;
    } else {
      this.btn_9_en = false;
      this.btn_11_class = this.notactivedBtn;
    }
    if (this.utilService.getDifferenceInHours(new Date(dt_06), currentDate) >= 24) {
      this.btn_12_en = true;
    } else {
      this.btn_12_en = false;
      this.btn_18_class = this.notactivedBtn;
    }

   if ((this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) >= 7)) {
      this.btn_7_en = true;
    } else {
      this.btn_7_en= false;
        this.btn_7_class = this.notactivedBtn;
    }

    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 7)) {
      this.btn_13_en = true;
    } else {
      console.log(this.utilService.getDifferenceInHours(new Date(dt_08), currentDate),"620")
        this.btn_13_en = false;
        this.btn_13_class = this.notactivedBtn
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 6)) {
      // if ((this.utilService.getDifferenceInHours(new Date(dt_4), currentDate) >= 2.99)) {
      this.btn_14_en = true;
    } else {
      console.log(this.utilService.getDifferenceInHours(new Date(dt_08), currentDate),"628")
      this.btn_14_en= false;
      this.btn_14_class = this.notactivedBtn;
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 5)) {
      this.btn_15_en = true;
    } else {
      console.log(this.utilService.getDifferenceInHours(new Date(dt_08), currentDate),"637")
        this.btn_15_en= false;
        this.btn_15_class = this.notactivedBtn;
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 4)) {
      this.btn_16_en = true;
    } else {
      console.log(this.utilService.getDifferenceInHours(new Date(dt_08), currentDate),"644")
        this.btn_16_en = false
        this.btn_16_class= this.notactivedBtn
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 3)) {
      this.btn_17_en = true;
    } else {
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        this.btn_17_en = false;
        this.btn_17_class = this.notactivedBtn
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 2)) {
      this.btn_18_en = true;
    } else {
      this.btn_18_en = false;
      this.btn_018_class = this.notactivedBtn
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_08), currentDate) >= 1)) {
      this.btn_19_en = true;
    } else {
      this.btn_19_en= false;
      this.btn_19_class = this.notactivedBtn;
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        let nextDate = new Date(this.model.year, this.model.month - 1, this.model.day + 1);
        console.log(nextDate,"477");
      
        this.model = {
            year: nextDate.getFullYear(),
            month: nextDate.getMonth() + 1,
            day: nextDate.getDate()
        }; 
    }
    if (!this.btn_12_en && !this.btn_9_en && !this.btn_7_en && !this.btn_3_en) {
      this.deliveryDT = '';
    }
 this.formatDate(dt_09)
  }
  else {
     let currentDate = new Date();
     let dt_09 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T11:00:00";
     // let dt_12 = this.model.year + "-" + this.model.month + "-" + this.model.day + " 12:00:00";
     // let dt_03 = this.model.year + "-" + this.model.month + "-" + this.model.day + " 15:00:00";
     let dt_23 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T23:00:00";
     let dt_06 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T18:00:00";
     let dt_07 = this.model.year + "-" + this.utilService.properFormatNumber(this.model.month) + "-" + this.utilService.properFormatNumber(this.model.day) + "T19:00:00";
    
     // console.log("dt09:-" + dt_09);
     // console.log("date dt09:-" + new Date(dt_09));
     // console.log("currentDate:-" + currentDate);
     // console.log("difference:-" + this.utilService.getDifferenceInHours(new Date(dt_09), currentDate));
 //  this.showDate = new Date(dt_09)
 
     if (this.utilService.getDifferenceInHours(new Date(dt_09), currentDate) >= 24) {
       this.btn_9_en = true;
     } else {
       this.btn_9_en = false;
       this.btn_11_class = this.notactivedBtn;
     }
     // if (this.utilService.getDifferenceInHours(new Date(dt_12), currentDate) >= 24) {
     //   this.btn_12_en = true;
     // } else {
     //   this.btn_12_en = false;
     // }
     // if (this.utilService.getDifferenceInHours(new Date(dt_03), currentDate) >= 24) {
     //   this.btn_3_en = true;
     // } else {
     //   this.btn_3_en = false;
     // }
     if (this.utilService.getDifferenceInHours(new Date(dt_06), currentDate) >= 24) {
       this.btn_12_en = true;
     } else {
       this.btn_12_en = false;
       this.btn_18_class = this.notactivedBtn;
     }
     console.log(this.utilService.getDifferenceInHours(new Date(dt_23), currentDate),"1-4")
     if ((this.utilService.getDifferenceInHours(new Date(dt_23), currentDate) >= 18)) {
       this.btn_20_en = true;
     } else {
       // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
         this.btn_20_en = false;
         this.btn_20_class = this.notactivedBtn
     }
     if ((this.utilService.getDifferenceInHours(new Date(dt_23), currentDate) >= 12)) {
      this.btn_21_en = true;
    } else {
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        this.btn_21_en = false;
        this.btn_21_class = this.notactivedBtn
    }
    if ((this.utilService.getDifferenceInHours(new Date(dt_23), currentDate) >= 12)) {
      this.btn_22_en = true;
    } else {
      // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
        this.btn_22_en = false;
        this.btn_22_class = this.notactivedBtn
            // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
         // If the current time is past 12:00 PM and the minimum time is set to 12, increment the selected date by one day
         let nextDate = new Date(this.model.year, this.model.month - 1, this.model.day + 1);
         console.log(nextDate,"778");
         // localStorage.setItem("Date_check",JSON.stringify(new Date(dt_07)))
         this.model = {
             year: nextDate.getFullYear(),
             month: nextDate.getMonth() + 1,
             day: nextDate.getDate()
         };
    }
    // if ((this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) >= 7)) {
      
       
    //    this.btn_7_en = true;
    //    console.log("255");
    //  } else {
    //    // if (this.utilService.getDifferenceInHours(new Date(dt_07), currentDate) <= 9) {
    //      // If the current time is past 12:00 PM and the minimum time is set to 12, increment the selected date by one day
    //      let nextDate = new Date(this.model.year, this.model.month - 1, this.model.day + 1);
    //      console.log(nextDate,"549");
    //      // localStorage.setItem("Date_check",JSON.stringify(new Date(dt_07)))
    //      this.model = {
    //          year: nextDate.getFullYear(),
    //          month: nextDate.getMonth() + 1,
    //          day: nextDate.getDate()
    //      };
    //  }
   
     
     if (!this.btn_12_en && !this.btn_9_en && !this.btn_7_en) {
       this.deliveryDT = '';
     }
  this.formatDate(dt_09)
  }
  }
  formatDate(d) {
    const inputDate = new Date(d);
    this.showDate = inputDate.toLocaleDateString('en-US', { weekday: 'short', day: 'numeric', month: 'short' });
  
    // console.log(this.showDate); // Outputs "Tue, 19 Sep"
  }
  ngAfterViewInit() {
    this.getCPKM();
  }

  getCPKM() {
    this.apiService.getAPI(this.apiService.BASE_URL + "cart/getCPKM").then((result) => {
      if (result.status) {
        this.cpkm = result.result.cpkm;
        this.base_delivery_charge = result.result.base_delivery_charge;
        this.packaged_gst = result.result.packaged_gst;
        this.cooked_gst = result.result.cooked_gst;
        this.delivery_charges_gst = result.result.delivery_charges_gst;
        // this.commission = result.result.commission;

        this.getUserAddress()
        this.getUserCartItems()
      }
    }, (error) => {
      //console.log("error");
    })
  }
  removeCoupon() {
    this.couponApplied = false;
    this.couponValue = undefined;
    this.message = 'Coupon removed.';
  }
  getCoupon(id) {
    this.message = '';
    this.apiService.postAPI(this.apiService.BASE_URL + "chefstatus/getOrderCouponByCode", {
code:id,userId: this.utilService.getUserID()}).then((result) => {
    if (result.status) {
      // Coupon successfully applied
      this.coupon = result.result;
      this.message = "Coupon applied successfully.";
      console.log(this.coupon, "Coupon applied successfully");
      // Optionally, you can perform additional actions here
      // this.getUserAddress();
      // this.getUserCartItems();
      // let base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
      let base_amount = Number((this.totalPrice).toFixed(2));
      // console.log("base amount:-" + base_amount);
      let amount = Number((base_amount * 100).toFixed(2));
      if (this.coupon && this.coupon.minimum_value && base_amount < this.coupon.minimum_value) {
        this.message = `Coupon is not applicable for this cart. Minimum cart value required: ${this.coupon.minimum_value}`;
        this.couponApplied = false;
        return;
      }
      if (this.coupon && this.coupon.type) {
        this.couponApplied = true;
        if (this.coupon.type === 'percent') {
          // amount -= Number(((this.coupon.coupon_value / 100)*100).toFixed(2));
          let discount = (this.coupon.coupon_value / 100) * amount; // Calculate discount amount
          amount -= Math.floor(discount);
        } else if (this.coupon.type === 'fixed') {
          amount -= Number((this.coupon.coupon_value* 100).toFixed(2));
          if (amount < 0) {
            amount = 0;
          }
        }
        this.discountedTotalAmount = Number((amount / 100).toFixed(2));
      }
      else{
        this.couponApplied = false;
      }
      // console.log(amount,"amount")
    } else {
      // Coupon application failed (invalid coupon or other error)
      console.log("Coupon application failed or invalid");
      this.message = result.message;
    }
  })
  .catch((error) => {
    console.error("Error applying coupon:", error);
    this.message = "An error occurred. Please try again later.";
  });
  }
// viewMoreBtn(){
//   this.viewMore = !this.viewMore
//   console.log(this.viewMore,"view more");
  
// }
viewMoreBtn(address: any) {
  address.expanded = !address.expanded;
}

  private getUserCartItems() {
    this.totalPrice = 0;
    this.chefPrice = 0;
    this.totalTax = 0;
    this.cartItems = [];
    if (this.utilService.isUserLoggedIn()) {
      this.apiService.getAPI(this.apiService.BASE_URL + "cart/getCartItemDetailByUserId/" + this.utilService.getUserID()).then((result) => {
        if (result.status) {
          this.cartItems = result.result;
          localStorage.setItem("chef_id_check",result?.result[0]?.itemDetail?.chef_id)
          // localStorage.setItem("chef_id_check",this.cartItems[0].itemDetail.chef_id)
	  localStorage.setItem("same_day",result?.result[0]?.itemDetail?.min_time)
this.apiService.postAPI(this.apiService.BASE_URL + "user/getChefFullDetailByChefId", {
                  chef_id: this.cartItems[0].itemDetail?.chef_id,
                }).then((result) => {
                  if (result.status) {
                    this.commission = result.result.profile.commission;
                    console.log(this.commission,"commission id")
                    console.log(this.commission,"commission id 606")
          this.reload();
          let isEvent = false;
          for (let i = 0; i < this.cartItems.length; i++) {
            if (this.cartItems[i].item_id != null) {
              this.totalPrice += Number(this.cartItems[i].price);
              this.chefPrice += this.utilService.getChefOrderPrice(this.cartItems[i].price, this.commission);
              this.min_order_val = result.result.profile.min_order_val;
              console.log(result.result.profile,"chefprice");
              console.log(this.min_order_val,"this.commissione 614");
              
              if (this.packaged_gst != 0 && this.cooked_gst != 0) {
                if (this.cartItems[i].category == 1) {
                  this.totalTax += Number(this.cartItems[i].price) * (this.packaged_gst / 100)
                } else {
                  this.totalTax += Number(this.cartItems[i].price) * (this.cooked_gst / 100)
                }
              }
            } else {
              isEvent = true;
            }
          }

          if (isEvent) {
            this.totalPrice = Number(this.cartItems[0].price);
            this.apiService.getAPI(this.apiService.BASE_URL + 'events/getEventDetails/' + this.cartItems[0].event_id).then((result) => {
              if (result.status) {

                this.eventDetail = result.result;

                this.cpkm = 0;
                this.base_delivery_charge = 0;
                this.packaged_gst = 0;
                this.cooked_gst = 0;
                this.deliveryCharges = 0;
                this.deliveryServiceCharges = 0;
                this.totalTax = 0;
                this.delivery_charges_gst = 0;

                this.totalTax += Number(this.cartItems[0].price) * (result.result.gst / 100);

                this.cpkm = result.result.delivery_per_km;
                this.base_delivery_charge = result.result.delivery_charge;
                this.packaged_gst = result.result.gst;
                this.cooked_gst = result.result.gst;
                this.delivery_charges_gst = result.result.delivey_gst;
                  
                this.chefAddress = {
                  lat: result.result.lat,
                  lng: result.result.lng
                }
                this.calculateDeliveryCharges()
              }
            })
          } else {
            this.totalTax = Number(this.totalTax.toFixed(2));
            this.getChefDetail(this.cartItems[0].item_id);
          }
                  }
                })
              // }
              
        } else {
          this.totalPrice = 0;
          this.totalTax = 0;
        }
      }, (error) => {
        //console.log("error");
      })
    }
  }

  getChefDetail(item_id: any) {
    this.apiService.postAPI(this.apiService.BASE_URL + "items/getChefAddressDetailByItemId", {
      item_id: item_id
    }).then((result) => {
      if (result.status) {
        this.chefAddress = result.result;
        // this.calculateDeliveryCharges()
        this.calculateDeliveryCharges()
        this.getChefDetailById(result.result.user_id);
      }
    }, (error) => {
      //console.log("something went wrong");
    })
  }
  getAllCoupon() {
    this.apiService.getAPI(this.apiService.BASE_URL + "chefStatus/getActiveCoupon").then((result) => {
      if (result.status) {
       console.log(result)
       this.couponData = result?.result
       console.log(this.couponData,"coupondata")
      }
    }, (error) => {
      //console.log("something went wrong");
    })
  }
  chefProfile = null;
  profile_pic: any = null;

  getChefDetailById(chef_id) {
    this.apiService.postAPI(this.apiService.BASE_URL + "user/getChefFullDetailByChefId", {
      chef_id: chef_id,
    }).then((result) => {
      if (result.status) {
        // console.log(result.result)
        localStorage.setItem("min_time",result.result.profile.min_time)
        this.chefProfile = result.result.profile;
        console.log(this.chefProfile.id,"id")
        // this.commission =  this.chefProfile.commission;
        // this.getcomission(this.chefProfile.commission)
      }
      if((this.chefProfile.profile_pic).includes("uploads")){
      this.profile_pic = this.apiService.BASE_IMAGE_URL + this.chefProfile.profile_pic;
      } else {
        this.profile_pic = this.utilService.getImageCDNURL(this.chefProfile.profile_pic)
      }
      this.cuisines = result.result.cuisines;
      for (let i = 0; i < this.cuisines.length; i++) {
          if (i == 0) {
              this.chef_special_cuisine = this.cuisines[i].name;
          } else {
              this.chef_special_cuisine = this.chef_special_cuisine + ', ' + this.cuisines[i].name;
          }
      }
      // if (this.profile != null) {
      //   if (this.profile.profile_pic == null || this.profile.profile_pic == '') {
      //     this.profile.profile_pic = 'assets/images/default_chef.png';
      //   } else {
      //     this.profile.profile_pic = this.apiService.BASE_IMAGE_URL + this.profile.profile_pic;
      //   }
      // }
    })
  }

  private getcomission(chefId: string) {
    return this.apiService.postAPI(this.apiService.BASE_URL + "user/getChefFullDetailByChefId", {
      chef_id: chefId,
    });
  }
// private getcomission(chefId: string): Promise<number> {
//   return new Promise((resolve, reject) => {
// this.apiService.postAPI(this.apiService.BASE_URL + "user/getChefFullDetailByChefId", {
//       chef_id: chefId,
//     }).then((result) => {
//       if (result.status) {
//         resolve(result.result.commission);
//       } else {
//         // Handle error
//         reject("Failed to fetch chef details");
//       }
//     }, (error) => {
//       // Handle error
//       reject("Failed to fetch chef details");
//     });
//   });
// }
  addItem(item: any) {
    let postData;
    if (item.item_id != null) {
      postData = {
        user_id: this.utilService.getUserID(),
        item_id: item.item_id,
        quantity: item.quantity + 1,
        price: (item.itemDetail.base_price * (item.quantity + 1))
      }
    } else if (item.event_id != null) {
      postData = {
        user_id: this.utilService.getUserID(),
        event_id: item.event_id,
        quantity: item.quantity + 1,
        price: (item.eventDetail.price * (item.quantity + 1))
      }
    }

    this.apiService.postAPI(this.apiService.BASE_URL + "cart/addToCart", postData).then((result) => {
      if (result.status) {
        this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
        // this.getUserCartItems()
        this.couponApplied = false;
        this.couponValue = ''
        this.message = ''
      }
    }, (error) => {
      //console.log("error");
    })
  }

  remove(item: any) {
    let postData;
    if (item.item_id != null) {
      postData = {
        user_id: this.utilService.getUserID(),
        item_id: item.item_id,
        quantity: item.quantity - 1,
        price: (item.itemDetail.base_price * (item.quantity - 1))
      }
    } else if (item.event_id != null) {
      postData = {
        user_id: this.utilService.getUserID(),
        event_id: item.event_id,
        quantity: item.quantity - 1,
        price: (item.eventDetail.price * (item.quantity - 1))
      }
    }
    
    this.apiService.postAPI(this.apiService.BASE_URL + "cart/addToCart", postData).then((result) => {
      
      if (result.status) {
          this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
          if(item.quantity - 1 == 0){
          this.deliveryCharges = 0;
          this.totalTax=0;
          this.comment = '';
          this.deliveryServiceCharges=0;
          this.chefProfile = null
          }
          this.couponApplied = false;
          this.couponValue = ''
          this.message = ''
        // this.getUserCartItems()
      }
    }, (error) => {
      //console.log("error");
    })
  }

  getUserDetail(showPhoneVerification) {
    this.apiService.postAPI(this.apiService.BASE_URL + "user/getUserDetailById", {
      id: this.utilService.getUserID()
    }).then((result) => {
      if (result.status) {
        if (result.result.phone == null) {
          // if (result.result.phone_verified == 0) {
          if (showPhoneVerification) {
            this.toaster.error("Please update phone number in profile to continue");
            // this.toaster.error("Please verify phone number to continue");
            // this.openphoneverificationModal()
          }
        } else {
          // this.payWithRazorpay()
          this.placeorder()
        }
      } else {
        this.toaster.error("user not found")
      }
    }, (error) => {
      //console.log("error");
    })
  }

  openphoneverificationModal() {
    const modalRef = this.modalService.open(PhoneverficationComponent, { size: '' });
    modalRef.componentInstance.send_confirmation = "1";
    modalRef.componentInstance.reset_password = "0";
    modalRef.closed.subscribe((result) => {
      // this.placeorder()
      this.getUserDetail(false);
    })
    modalRef.dismissed.subscribe((result) => {
      this.getUserDetail(false);
    })
  }

  placeorder() {
    if (!this.utilService.isUserLoggedIn()) {
      this.toaster.error("please login")
      return;
    }

    if (this.cartItems.length == 0) {
      this.toaster.error("please add items to cart");
      return;
    }
    if (this.getSelectedUserAddress() == null) {
      this.toaster.error("There seems to be an error with the address details. Please add or select a valid address!");
      return;
    }
    if (this.chefAddress == null) {
      this.toaster.error("Chef address not defined");
      return;
    }
    // console.log("delivery DT:-" + this.deliveryDT);
    if (this.deliveryDT == '') {
      this.toaster.error("Please select delivery date time");
      return;
    }
    if (new Date(this.deliveryDT)) {
      // if ((new Date(this.deliveryDT).getTime() - new Date().getTime()) / (1000 * 60 * 60) >= 2) {
      console.log("same date")
      // this.deliveryDT = '';
      // this.toaster.error("Delivery date time must be 7 hours later");
      // return;
    }else {
      (new Date(this.deliveryDT).getTime() - new Date().getTime()) / (1000 * 60 * 60) < 24
        this.deliveryDT = '';
        // console.log("else");
        
        this.toaster.error("Delivery date time must be 24 hours later");
        return;
      
    }
    // if ((new Date(this.deliveryDT).getTime() - new Date().getTime()) / (1000 * 60 * 60) < 24) {
    //   this.deliveryDT = '';
    //   this.toaster.error("Delivery date time must be 24 hours later");
    //   return;
    // }

    //console.log("chefAddress:-" + JSON.stringify(this.chefAddress));
    // this.getUserDetail()
    // this.payWithRazorpay()
    this.generateOrderId();
    // this.checkAddressInGeofence()
  }

  generateOrderId() {
    //  let base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
    let main_amount;
    if (this.coupon && this.coupon.type) {
      let base_amount = Number((this.totalPrice).toFixed(2));
      // console.log("base amount:-" + base_amount);
      let amount = Number((base_amount * 100).toFixed(2));
      // this.couponApplied = true;
      if (this.coupon.type === 'percent') {
        // amount -= Number(((this.coupon.coupon_value / 100)*100).toFixed(2));
        let discount = (this.coupon.coupon_value / 100) * amount; // Calculate discount amount
        amount -= Math.floor(discount);
      } else if (this.coupon.type === 'fixed') {
        amount -= Number((this.coupon.coupon_value* 100).toFixed(2));
        if (amount < 0) {
          amount = 0;
        }
      }
      let totalAmount = amount / 100
      let AddTax = Number((totalAmount + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2))
      main_amount = Number((AddTax * 100).toFixed(2));
      // this.discountedTotalAmount = Number((amount * 100).toFixed(2));
      console.log(main_amount,"1171")
      console.log((this.deliveryCharges + this.deliveryServiceCharges + this.totalTax),"1172")
    } else{
      let base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
      console.log("base amount:-" + this.totalPrice);
      console.log(base_amount,"1176");
       main_amount = Number((base_amount * 100).toFixed(2));
    }
    // let main_amount = Number(amount + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2)
    // else{
    //   this.couponApplied = false;
    // }
    console.log(main_amount,"amount")
    this.apiService.postAPI(this.apiService.BASE_URL + "generateOrderId", {
      amount: main_amount
    }).then((result) => {
      // console.log("result:-" + JSON.stringify(result));
      if (result.status) {
        // this.payWithRazorpay(result.result.id)
        this.placePreOrder(result.result.id);
      } else {
        // console.log("error:-"+JSON.stringify(error))
        this.toaster.error(result.result.error.description)
      }
    }, (error) => {
      // console.log("error:-" + JSON.stringify(error));
      this.toaster.error("something went wrong");
      //console.log("error");
    })
  }
  

  placePreOrder(orderId) {
    let userProfile = this.utilService.getUserProfile();
    let person_name = userProfile.name;
    let phone = userProfile.phone;
// let base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
    let base_amount;

  // Apply coupon discount
  if (this.coupon && this.coupon.type) {
    let main_amount = Number((this.totalPrice).toFixed(2));
    if (this.coupon.type === 'percent') {
      main_amount -= (this.coupon.coupon_value / 100) * main_amount;
      // base_amount -= (1 - (this.coupon.coupon_value / 100));
    } else if (this.coupon.type === 'fixed') {
      main_amount -= this.coupon.coupon_value;
      if (base_amount < 0) {
        main_amount = 0;
      }
    }
base_amount = Number((main_amount + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));

  } else {
     base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
  }
  console.log(base_amount,"base_amunt")
    let address = this.getSelectedUserAddress();
    let postData = {
      user_id: this.utilService.getUserID(),
      // chef_id: this.chefAddress.user_id,
      address_id: this.getSelectedUserAddress().id,
      // total_items: this.cartItems.length,
      base_price: this.totalPrice,
      chef_price: this.chefPrice,
      tax: this.totalTax,
      razorpay_id: "",
      total_price: base_amount,
      delivery_charges: this.deliveryCharges,
      delivery_service_tax: this.deliveryServiceCharges,
      person_name: person_name,
      delivery_dt: this.deliveryDT,
      timeslot: this.timeslot,
      phone: phone,
      comment: this.comment,
      lat: address.lat,
      lng: address.lng,
      address: address.address,
      landmark: address.landmark,
      formatted_address: address.formatted_address,
      address_type: address.address_type,
      order_id: orderId
    }

    // console.log(postData)

    if (this.cartItems[0].event_id != null) {
      postData['event_id'] = this.cartItems[0].event_id;
      postData['total_items'] = this.cartItems[0].quantity;
    } else {
      postData['chef_id'] = this.chefAddress.user_id;
      let total_items = 0;
      for (let i = 0; i < this.cartItems.length; i++) {
        total_items += this.cartItems[i].quantity;
      }
      postData['total_items'] = total_items;
    }

    this.apiService.postAPI(this.apiService.BASE_URL + "order/addPreOrder", postData).then((result) => {
      if (result.status) {
        this.payWithRazorpay(orderId, result.result.id);
      } else {
        this.toaster.error(result.message)
      }
    }, (error) => {
      // console.log("something went wrong:-" + error.message);
      this.toaster.error(error.message)
    })
  }

  checkAddressInGeofence() {
    let location = this.getSelectedUserAddress();
    // console.log("location:-" + JSON.stringify(location));
    this.apiService.postAPI(this.apiService.BASE_URL + "geofence/checkOrderInGeofence", {
      lat1: location.lat,
      lng1: location.lng
    }).then((result) => {
      if (result.status) {
        this.generateOrderId()
      } else {
        this.toaster.error(result.message)
      }
    }, (error) => {
      //console.log("error");
      this.toaster.error("Something went wrong");
    })
  }

  payWithRazorpay(orderId, preOrderId) {
    let base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
    // console.log("base amount:-" + base_amount);
    let amount = base_amount * 100
    // console.log("paying razorpay:-" + amount);
    // console.log(amount);
    const options: any = {
      // key: 'rzp_test_nOpoMazrOFihin',
      key: this.RZP_KEY,
      amount: Number(amount.toFixed(2)), // amount should be in paise format to display Rs 1255 without decimal point
      currency: 'INR',
      name: "Payment for order",
      description: "Payment for the order for we the chefs",
      image: 'https://wethechefs.in/assets/customer-profile-images/Logo.png',
      "prefill": {
        "name": this.utilService.getUserProfile().name,
        "email": this.utilService.getUserEmail(),
        "contact": this.utilService.getUserProfile().phone
      },
      order_id: orderId, // order_id created by you in backend
      theme: {
        color: '#E26812'
      }
    };
    // options.modal = {
    //   escape: false, ondismiss: function () {
    //     console.log("modal closed");
    //     this.apiService.postAPI(this.apiService.BASE_URL + "order/addPreOrderFailedError", JSON.stringify({
    //       error:{
    //         reason:'Payment popup closed by user'
    //       }
    //     })).then((result) => {
    //     }, (error) => {
    //       //console.log("something went wrong");
    //     })
    //   }
    // }


    options.modal = {
      escape: false, ondismiss: (() => {
        // console.log("modal closed by user");
        this.updatePreOrderError(preOrderId, {
          "error": {
            "reason": "Payment popup closed by user"
          }
        })
      })
    }
    options.handler = ((response, error) => {
      if (error) {
        // console.log("error:-" + JSON.stringify(error));
        this.updatePreOrderError(preOrderId, error)
        this.toaster.error("We are sorry to not able to process your order! please contact WTC for any queries");
        return;
      }
      options.response = response;
      // console.log("Response:-" + JSON.stringify(response));
      // alert("Payment successfull & your transaction id is " + response.razorpay_payment_id);
      this.placeOrderAPI(response.razorpay_payment_id, orderId, preOrderId);
    });

    var rzp1 = new Razorpay(options);
    rzp1.open();
    rzp1.on('payment.failed', (response) => {
      // console.log("failed response:-" + JSON.stringify(response))
      // alert(response.error.code);
      // alert(response.error.description);
      // alert(response.error.source);
      // alert(response.error.step);
      this.toaster.error(response.error.reason);

      this.updatePreOrderError(preOrderId, response.error)

      // alert(response.error.metadata.order_id);
      // alert(response.error.metadata.payment_id);
    });
    rzp1.on('ondismiss', (response) => {
      // console.log("close response:-" + JSON.stringify(response))
    }, (error) => {
      // console.log("close error:-" + JSON.stringify(error))
    })
  }

  updatePreOrderError(preOrderId, error) {
    this.apiService.postAPI(this.apiService.BASE_URL + "order/addPreOrderFailedError", {
      failed_error: JSON.stringify(error),
      id: preOrderId
    }).then((result) => {
    }, (error) => {
      //console.log("something went wrong");
    })
  }

  placeOrderAPI(razorPayId, orderId, preOrderId) {
    let userProfile = this.utilService.getUserProfile();
    let person_name = userProfile.name;
    let phone = userProfile.phone;

    let address = this.getSelectedUserAddress();
    // let base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));

    // if (this.coupon && this.coupon.type) {
    //   if (this.coupon.type === 'percent') {
    //     base_amount -= (this.coupon.coupon_value / 100) * base_amount;
    //   } else if (this.coupon.type === 'fixed') {
    //     base_amount -= this.coupon.coupon_value;
    //     if (base_amount < 0) {
    //       base_amount = 0;
    //     }
    //   }
    // }
    let base_amount;

    // Apply coupon discount
    if (this.coupon && this.coupon.type) {
      let main_amount = Number((this.totalPrice).toFixed(2));
      if (this.coupon.type === 'percent') {
        main_amount -= (this.coupon.coupon_value / 100) * main_amount;
        // base_amount -= (1 - (this.coupon.coupon_value / 100));
      } else if (this.coupon.type === 'fixed') {
        main_amount -= this.coupon.coupon_value;
        if (base_amount < 0) {
          main_amount = 0;
        }
      }
  base_amount = Number((main_amount + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
  
    } else {
       base_amount = Number((this.totalPrice + this.deliveryCharges + this.deliveryServiceCharges + this.totalTax).toFixed(2));
    }
    let postData = {
      user_id: this.utilService.getUserID(),
      // chef_id: this.chefAddress.user_id,
      address_id: this.getSelectedUserAddress().id,
      // total_items: this.cartItems.length,
      base_price: this.totalPrice,
      chef_price: this.chefPrice,
      tax: this.totalTax,
      razorpay_id: razorPayId,
      total_price: base_amount,
      delivery_charges: this.deliveryCharges,
      delivery_service_tax: this.deliveryServiceCharges,
      person_name: person_name,
      delivery_dt: this.deliveryDT,
      timeslot: this.timeslot,
      phone: phone,
      comment: this.comment,
      lat: address.lat,
      lng: address.lng,
      address: address.address,
      landmark: address.landmark,
      formatted_address: address.formatted_address,
      address_type: address.address_type,
      order_id: orderId,
      preOrderId: preOrderId
    }

    if (this.cartItems[0].event_id != null) {
      postData['event_id'] = this.cartItems[0].event_id;
      postData['total_items'] = this.cartItems[0].quantity;
    } else {
      postData['chef_id'] = this.chefAddress.user_id;
      let total_items = 0;
      for (let i = 0; i < this.cartItems.length; i++) {
        total_items += this.cartItems[i].quantity;
      }
      postData['total_items'] = total_items;
    }

    this.apiService.postAPI(this.apiService.BASE_URL + "order/addOrder", postData).then((result) => {
      if (result.status) {
        this.eventService.emit(new EmitEvent(Events.CART_ITEM_UPDATE, ''));
        this.getUserCartItems();
        this.deliveryCharges = 0;
        this.comment = '';
        localStorage.setItem('invoice_number', result.result.invoice_number)
        // localStorage.setItem('order_id',result.result.id)
        // this.invoiceService.setInvoiceNumber(result.result.invoice_number);
      this.orderService.setOrderNumber(result.result.id);
        this.downloadInvoice(result.result.id);
        this.openPlacedOrder(result)
        if(this.coupon){
          this.addCouponsLogs(result.result.uid,result.result.chef_id)
        }
      }
    }, (error) => {
      //console.log("something went wrong");
    })
  }

  addCouponsLogs(uid,chef_id){
let postData = {
  user_id: this.utilService.getUserID(),
  chef_id: chef_id, // Replace with actual chef_id
  order_number: uid, // Replace with actual order_number
  coupon_value: this.coupon.coupon_value, // Replace with actual coupon_value
  coupon_code: this.coupon.coupon_code // Replace with actual coupon_code
}
this.apiService.postAPI(this.apiService.BASE_URL + "chefStatus/addCouponLogs", postData).then((result) => {
  if (result.status) {
   
  }
}, (error) => {
  //console.log("something went wrong");
})
  }
  // setOrderId(result){

  // }
  openPlacedOrder(result){
    
    // localStorage.setItem("invoice_number",result.result.invoice_number)
    // localStorage.setItem("order_id",result.result.id)
    
    // this.utilService.SharingInvoice.next(result?.result.invoice_number);
    if (result.status == true){
      // this.router.navigateByUrl('/thank-you');
      
      // setTimeout(() => {
      this.router.navigate(['/thank-you']);
      // }, 2000);
    }
    // const modalRef = this.modalService.open(OrderpopupComponent, { size: '' });
    // modalRef.componentInstance.uid = result?.invoice_number;
    // modalRef.componentInstance.orderId = result?.id;
    // modalRef.closed.subscribe((result) => {
    //   this.router.navigateByUrl('/home');
    // })
  }

  getUserAddress() {
    this.userAddresses = [];
    this.addPaymentDisabled= false
    this.apiService.getAPI(this.apiService.BASE_URL + "user/userAddress/" + this.utilService.getUserID()).then((result) => {
      if (result.status) {

        this.userAddresses = result.result;
        this.addDisabled= true
        if(this.deliveryDT != '' && this.userAddresses != null ){
          this.getPaymentDisable(true)
          this.addPaymentDisabled= true
        } else {
          this.addPaymentDisabled= false
          this.getPaymentDisable(false)
        }
        for (let i = 0; i < this.userAddresses.length; i++) {
          if (i == 0) {
            this.userAddresses[i]['selected'] = true;
            this.calculateDeliveryCharges()
          }
        }
        // this.calculateDeliveryCharges()
      }

    }, (error) => {
      //console.log("something went wrong");
    })
  }

  addNewAddress() {
    const modalRef = this.modalService.open(AddUpdateAddressComponent, {
      size: '',
      windowClass: "mobileClass",
      centered: true
    });
    modalRef.closed.subscribe((result) => {
      this.getUserAddress();
    })
    // modalRef.componentInstance.chefId = item.id;
  }

  editAddress(address: any) {
    const modalRef = this.modalService.open(AddUpdateAddressComponent, {
      size: '',
      windowClass: "mobileClass",
      centered: true
    });
    modalRef.closed.subscribe((result) => {
      this.getUserAddress();
    })
    modalRef.componentInstance.userAddress = address;
  }

  deleteAddress(address: any) {
    const modalRef = this.modalService.open(ConfirmationModalComponent, { size: '' });
    modalRef.closed.subscribe((result) => {
      if (result.confirm) {
        this.apiService.deleteAPI(this.apiService.BASE_URL + "user/deleteUserAddress/" + address.id).then((result) => {
          if (result.status) {
            this.getUserAddress()
          }
        }, (error) => {
          //console.log("something went wrong");
        })
      }
    })
    modalRef.componentInstance.title = 'Confirmation';
    modalRef.componentInstance.message = 'Do you want to delete the address?';
  }
  removeExtraHyphens(inputString: string): string {
    return inputString.replace(/-+/g, '-');
}
  openChefDetail(id: any) {
    // this.utilService.setItem('chefId', id);
    // this.router.navigate(['/chefdetail'], { queryParams: { chef_id: id } });
    this.apiService.postAPI(this.apiService.BASE_URL+"user/getChefBasicProfile",{
      chef_id:id
    }).then((result)=>{
      if(result.status){
        if(result.result!=null){
          let name=result.result.name;
          name = name.replace(/ /g, '-');
          if (name.endsWith('-')) {
            name = name.slice(0, -1);
          }
          let ChefName = this.removeExtraHyphens(name)
          this.router.navigateByUrl('/chefdetail/' + ChefName);
          // this.router.navigate(['/chefdetail'], {queryParams: {chef_id: id,name:result.result.name}});
          // this.router.navigateByUrl('/chefdetail/' + name);
        }
      }
    })


    // this.router.navigateByUrl('/chefdetail/' + id);
  }

  openEventDetail(id: any) {
    this.utilService.setItem('event_id', id);
    // this.router.navigateByUrl('/coast-to-coast');
    // this.router.navigateByUrl('/event-detail/'+id);

    this.router.navigate(
      ['/event-detail'],
      { queryParams: { event_id: id } }
    );
    // this.router.navigateByUrl('/chefdetail/' + id);
  }

  selectedIndex = 0;

  onAddressSelected(i: number) {
    for (let i = 0; i < this.userAddresses.length; i++) {
      this.userAddresses[i].selected = false;
    }
    this.userAddresses[i].selected = true;
   
    this.selectedIndex = i;
    // console.log("address:-" + JSON.stringify(this.userAddresses[i], null, 4));
    this.calculateDeliveryCharges()
    if(this.deliveryDT != '' &&  this.userAddresses[i].selected == true){
      this.getPaymentDisable(true)
      // this.addPaymentDisabled= true
    } 
  }

  // calculateDeliveryCharges() {
  //   let selectedAddress = this.getSelectedUserAddress();
  //   if (selectedAddress != null && this.chefAddress != null && this.chefAddress != undefined) {
  //     this.getDistance(Number(this.chefAddress.lat), Number(this.chefAddress.lng), selectedAddress.lat, selectedAddress.lng).then((result: any) => {
  //       try {
  //         let distance = (result.rows[0].elements[0].distance.value) / 1000;
  //         this.distance = Math.round((distance));
  //         let distanceCharges = Number((distance * this.cpkm).toFixed(2));
  //         if (distanceCharges <= this.base_delivery_charge) {
  //           this.deliveryCharges = this.base_delivery_charge;
  //         } else {
  //           this.deliveryCharges = Number((distance * this.cpkm).toFixed(2));
  //         }

  //         this.deliveryServiceCharges = Number((this.deliveryCharges * (this.delivery_charges_gst / 100)).toFixed(2));

  //         if (this.userAddresses[this.selectedIndex].formatted_address.toLowerCase().includes("haryana")) {
  //           this.splitGST = true;
  //         } else {
  //           this.splitGST = false;
  //         }
  //       } catch (e) {
  //       }
  //     }, (error) => {
  //     })
  //   }
  // }
  calculateDeliveryCharges() {
    let selectedAddress = this.getSelectedUserAddress();
    if (selectedAddress && this.chefAddress) {
      this.getDistance(
        Number(this.chefAddress.lat),
        Number(this.chefAddress.lng),
        selectedAddress.lat,
        selectedAddress.lng
      ).then(
        (result: any) => {
          try {
            let distance = (result.rows[0].elements[0].distance.value) / 1000;
            this.distance = Math.round(distance);
  
            // Delivery charges based on distance range
            console.log(this.distance,"distance")
            if (this.distance <= 5) {
              this.deliveryCharges = 75;
            } else if (this.distance > 5 && this.distance <= 10) {
              this.deliveryCharges = 120;
            } else if (this.distance > 10 && this.distance <= 20) {
              this.deliveryCharges = 150;
            } else {
              this.deliveryCharges = 250;
            }
  
            // Calculate GST
            this.deliveryServiceCharges = Number(
              (this.deliveryCharges * (this.delivery_charges_gst / 100)).toFixed(2)
            );
  
            // this.splitGST = this.userAddresses[this.selectedIndex]
            //   .formatted_address.toLowerCase()
            //   .includes("haryana");
            if (this.userAddresses[this.selectedIndex].formatted_address.toLowerCase().includes("haryana")) {
              this.splitGST = true;
            } else {
              this.splitGST = false;
            }
          } catch (e) {
            console.error("Error in delivery charge calculation:", e);
          }
        },
        (error) => {
          console.error("Error fetching distance:", error);
        }
      );
    }
  }

  public getDistance(srcLat, srcLng, destLat, destLng) {

    //console.log("srcLat:-" + srcLat);
    //console.log("srcLng:-" + srcLng);
    //console.log("destLat:-" + destLat);
    //console.log("destLng:-" + destLng);

    const matrix = new google.maps.DistanceMatrixService();
    return new Promise((resolve, reject) => {
      matrix.getDistanceMatrix({
        origins: [new google.maps.LatLng(srcLat, srcLng)],
        destinations: [new google.maps.LatLng(destLat, destLng)],
        travelMode: google.maps.TravelMode.DRIVING,
      }, function (response, status) {
        if (status === 'OK') {
          // //console.log("responses:-"+JSON.stringify(response));
          // //console.log("distance:-"+response.rows[0].elements[0].distance.value);
          resolve(response)
        } else {
          reject(response);
        }
      });
    })
  }

  getSelectedUserAddress() {
    for (let i = 0; i < this.userAddresses.length; i++) {
      if (this.userAddresses[i].selected) {
        return this.userAddresses[i];
      }
    }
    return null;
  }

  pdfOrder = null;

  downloadInvoice(orderId) {
    this.pdfOrder = null;
    this.apiService.getAPI(this.apiService.BASE_URL + "order/getOrderById/" + orderId).then((result) => {
      if (result.status) {
        // this.openPlacedOrder(result)
        this.pdfOrder = result.result;
        this.downloadInvoicePDF()
      }
    }, (error) => {
      console.log("error");
    })
  }

  downloadInvoicePDF() {

    // console.log('order :-' + JSON.stringify(order));
    // return;
    // this.pdfOrder = order;

    let totalQuantity = 0
    let taxAmount = 0
    let icgsttotal = 0
    let sgsttotal = 0
    let cgsttotal = 0
    let deliveryIgstPer = ''
    let deliverySGSTPer = ''

    let deliveryIgst = ''
    let deliverySGST = ''

    let delivery_charges_gst = 0;
    let packaged_gst = 0;
    let cooked_gst = 0;

    if (this.pdfOrder.event_id != null) {
      // this.commission = 0;
      delivery_charges_gst = this.pdfOrder.chef_location.delivey_gst;
      packaged_gst = this.pdfOrder.chef_location.gst;
      cooked_gst = this.pdfOrder.chef_location.gst;

      let orderItems = [];
      console.log(this.commission,"order")
      orderItems.push({
        "id": 0,
        "item_id": 0,
        "order_id": this.pdfOrder.id,
        "user_id": this.utilService.getUserID(),
        "quantity": this.pdfOrder.total_items,
        "price": this.pdfOrder.total_items * this.pdfOrder.chef_location.price,
        "commission": this.commission,
        "chef_price": this.utilService.getChefOrderPrice(this.pdfOrder.total_items * this.pdfOrder.chef_location.price, 15),
        "created_at": this.pdfOrder.delivery_at,
        "name": this.pdfOrder.chef_location.name,
        "qty": 0,
        "qty_type": 1,
        "base_price": this.pdfOrder.total_items * this.pdfOrder.chef_location.price,
        "images": "[\"/uploads/326860south indian.jpeg\"]",
        "calories": "500",
        "category": 2
      })

      this.pdfOrder.orderItems = orderItems;
    } else {
      delivery_charges_gst = this.delivery_charges_gst;
      packaged_gst = this.packaged_gst;
      cooked_gst = this.cooked_gst;
    }

    if (this.pdfOrder.hasOwnProperty('order_address') && this.pdfOrder.order_address != null && this.pdfOrder != undefined
      && this.pdfOrder.order_address.formatted_address.toLowerCase().includes('haryana')) {
      this.pdfOrder['isICGST'] = false;

      deliverySGSTPer = (delivery_charges_gst / 2).toFixed(2);
      deliveryIgstPer = '-';

      deliveryIgst = '-';
      deliverySGST = (this.pdfOrder.delivery_service_tax / 2).toFixed(2);

      for (let i = 0; i < this.pdfOrder.orderItems.length; i++) {
        totalQuantity += this.pdfOrder.orderItems[i].quantity;
        taxAmount += Number(this.pdfOrder.orderItems[i].price);
        if (this.pdfOrder.orderItems[i].category == 1) {
          this.pdfOrder.orderItems[i]['icgstper'] = '-';
          this.pdfOrder.orderItems[i]['sgstper'] = Number((packaged_gst / 2).toFixed(2));
          this.pdfOrder.orderItems[i]['cgstper'] = Number((packaged_gst / 2).toFixed(2));

          let gst = Number(((this.pdfOrder.orderItems[i].price * (packaged_gst / 100)) / 2).toFixed(2));

          this.pdfOrder.orderItems[i]['icgstamount'] = '-';
          this.pdfOrder.orderItems[i]['sgstamount'] = gst;
          this.pdfOrder.orderItems[i]['cgstamount'] = gst;

          sgsttotal += gst
          cgsttotal += gst

          let tax = Number((this.pdfOrder.orderItems[i].price * (packaged_gst / 100)).toFixed(2));
          this.pdfOrder.orderItems[i]['taxamount'] = tax;
          // taxAmount += tax;
          this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))
        } else {
          this.pdfOrder.orderItems[i]['icgstper'] = '-';
          this.pdfOrder.orderItems[i]['sgstper'] = Number((cooked_gst / 2).toFixed(2));
          this.pdfOrder.orderItems[i]['cgstper'] = Number((cooked_gst / 2).toFixed(2));

          let gst = Number(((this.pdfOrder.orderItems[i].price * (cooked_gst / 100)) / 2).toFixed(2));

          this.pdfOrder.orderItems[i]['icgstamount'] = '-';
          this.pdfOrder.orderItems[i]['sgstamount'] = gst;
          this.pdfOrder.orderItems[i]['cgstamount'] = gst;

          sgsttotal += gst
          cgsttotal += gst

          let tax = Number((this.pdfOrder.orderItems[i].price * (cooked_gst / 100)).toFixed(2))
          this.pdfOrder.orderItems[i]['taxamount'] = tax;
          // taxAmount += tax;
          this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))
        }
      }
    } else {
      this.pdfOrder['isICGST'] = true;

      deliverySGSTPer = '-';
      deliveryIgstPer = delivery_charges_gst.toFixed(2);


      deliveryIgst = (this.pdfOrder.delivery_service_tax).toFixed(2);
      deliverySGST = '-';

      for (let i = 0; i < this.pdfOrder.orderItems.length; i++) {

        totalQuantity += this.pdfOrder.orderItems[i].quantity;
        taxAmount += Number(this.pdfOrder.orderItems[i].price);

        if (this.pdfOrder.orderItems[i].category == 1) {
          this.pdfOrder.orderItems[i]['icgstper'] = packaged_gst;
          this.pdfOrder.orderItems[i]['sgstper'] = '-';
          this.pdfOrder.orderItems[i]['cgstper'] = '-';

          let gst = Number((this.pdfOrder.orderItems[i].price * (packaged_gst / 100)).toFixed(2));

          this.pdfOrder.orderItems[i]['icgstamount'] = gst
          this.pdfOrder.orderItems[i]['sgstamount'] == '-';
          this.pdfOrder.orderItems[i]['cgstamount'] = '-';

          icgsttotal += gst;

          let tax = Number((this.pdfOrder.orderItems[i].price * (packaged_gst / 100)).toFixed(2));
          this.pdfOrder.orderItems[i]['taxamount'] = tax;
          this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))
        } else {
          this.pdfOrder.orderItems[i]['icgstper'] = cooked_gst;
          this.pdfOrder.orderItems[i]['sgstper'] = '-';
          this.pdfOrder.orderItems[i]['cgstper'] = '-';

          let gst = Number((this.pdfOrder.orderItems[i].price * (cooked_gst / 100)).toFixed(2));

          this.pdfOrder.orderItems[i]['icgstamount'] = gst;
          this.pdfOrder.orderItems[i]['sgstamount'] == '-';
          this.pdfOrder.orderItems[i]['cgstamount'] = '-';

          icgsttotal += gst;

          let tax = Number((this.pdfOrder.orderItems[i].price * (cooked_gst / 100)).toFixed(2));
          this.pdfOrder.orderItems[i]['taxamount'] = tax;
          this.pdfOrder.orderItems[i]['totalamount'] = Number(this.pdfOrder.orderItems[i]['taxamount'] + Number(this.pdfOrder.orderItems[i].price))

        }
      }
    }


    this.pdfOrder['totalQuantity'] = totalQuantity
    this.pdfOrder['taxAmount'] = taxAmount
    this.pdfOrder['icgsttotal'] = icgsttotal
    this.pdfOrder['sgsttotal'] = sgsttotal
    this.pdfOrder['cgsttotal'] = cgsttotal
    this.pdfOrder['deliveryIgstPer'] = deliveryIgstPer
    this.pdfOrder['deliverySGSTPer'] = deliverySGSTPer
    this.pdfOrder['deliveryIgst'] = deliveryIgst
    if (deliveryIgst != '-') {
      this.pdfOrder['deliveryIgstAmount'] = Number(deliveryIgst)
    } else {
      this.pdfOrder['deliveryIgstAmount'] = 0
    }
    this.pdfOrder['deliverySGST'] = deliverySGST

    let fileName = '';
    if (this.pdfOrder.invoice_number != null && this.pdfOrder.invoice_number != '') {
      fileName = 'order_' + this.pdfOrder.invoice_number + '_';
    } else {
      fileName = 'order_' + this.utilService.formatReportName() + '_';
    }

    setTimeout(() => {

      // let innerHtml=document.getElementById('pdfTable').innerHTML;


      let orderTr = '';

      for (let i = 0; i < this.pdfOrder.orderItems.length; i++) {
        let item = this.pdfOrder.orderItems[i];
        let price = 0;
        if (item.price != undefined) {
          price = item.price;
        }
        let icgstper = 0;
        if (item.icgstper != undefined) {
          icgstper = item.icgstper;
        }
        let icgstamount = 0;
        if (item.icgstamount != undefined) {
          icgstamount = item.icgstamount;
        }
        let sgstper = 0;
        if (item.sgstper != undefined) {
          sgstper = item.sgstper;
        }
        let sgstamount = 0;
        if (item.sgstamount != undefined) {
          sgstamount = item.sgstamount;
        }
        let cgstper = 0;
        if (item.cgstper != undefined) {
          cgstper = item.cgstper;
        }
        let cgstamount = 0;
        if (item.cgstamount != undefined) {
          cgstamount = item.cgstamount;
        }
        let totalamount = 0;
        if (item.totalamount != undefined) {
          totalamount = item.totalamount;
        }
        orderTr += '<tr>\n' +
          '        <td>' + (i + 1) + '</td>\n' +
          '      <td>' + item.name + '</td>\n' +
          '      <td>' + item.quantity + '</td>\n' +
          '      <td>' + this.utilService.getFixedAmount(item.price / item.quantity, 2) + '</td>\n' +
          '      <td>' + price + '</td>\n' +
          '      <td>' + icgstper + '</td>\n' +
          '      <td>' + icgstamount + '</td>\n' +
          '      <td>' + sgstper + '</td>\n' +
          '      <td>' + sgstamount + '</td>\n' +
          '      <td>' + cgstper + '</td>\n' +
          '      <td>' + cgstamount + '</td>\n' +
          '      <td>' + totalamount + '</td>\n' +
          '      </tr>';
      }

      let orderTotalTr = '                    <tr class="font-weight">\n' +
        '                        <td></td>\n' +
        '                        <td>Total Item Cost</td>\n' +
        '                        <td>' + this.pdfOrder.totalQuantity + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + this.pdfOrder.taxAmount + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + this.utilService.getFixedAmount(this.pdfOrder.icgsttotal, 2) + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + this.utilService.getFixedAmount(this.pdfOrder.sgsttotal, 2) + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + this.utilService.getFixedAmount(this.pdfOrder.cgsttotal, 2) + '</td>\n' +
        '                        <td></td>\n' +
        '                    </tr>\n';

      let calculatedIcgst = 0;
      if (this.pdfOrder.icgsttotal != 0) {
        calculatedIcgst = this.utilService.getFixedAmount(this.utilService.calculateTotal(this.pdfOrder.icgsttotal, this.pdfOrder.deliveryIgstAmount), 2);
      }

      let calculatedscgst = 0;
      if (this.pdfOrder.sgsttotal != 0) {
        calculatedIcgst = this.utilService.getFixedAmount(this.utilService.calculateTotal(this.pdfOrder.sgsttotal, this.pdfOrder.deliverySGST), 2);
      }


      let orderFinalTotal = '<tr class="font-weight">\n' +
        '                        <td></td>\n' +
        '                        <td>Total Food Cost</td>\n' +
        '                        <td>' + this.pdfOrder.totalQuantity + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + (this.pdfOrder.taxAmount + this.pdfOrder.delivery_service_tax) + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + calculatedIcgst + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + calculatedscgst + '</td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + calculatedscgst + '</td>\n' +
        '                        <td>' + this.pdfOrder.total_price + '</td>\n' +
        '                    </tr>\n'


      let html = '<!DOCTYPE html>\n' +
        '<html lang="en">\n' +
        '\n' +
        '<head>\n' +
        '    <meta charset="UTF-8">\n' +
        '    <meta http-equiv="X-UA-Compatible" content="IE=edge">\n' +
        '    <meta name="viewport" content="width=device-width, initial-scale=1.0">\n' +
        '    <title>Document</title>\n' +
        '    <!-- CSS only -->\n' +
        '    <!-- <link href="https://cdn.jsdelivr.net/npm/bootstrap@5.1.3/dist/css/bootstrap.min.css" rel="stylesheet"\n' +
        '        integrity="sha384-1BmE4kWBq78iYhFldvKuhfTAU6auU8tT94WrHftjDbrCEXSU1oBoqyl2QvZ6jIW3" crossorigin="anonymous"> -->\n' +
        '\n' +
        '    <style>\n' +
        '        .card {\n' +
        '            background: white;\n' +
        '            border-radius: 10px;\n' +
        '            box-shadow: 0px 0px 5px 0px rgba(182, 182, 182, 0.5);\n' +
        '            padding: 25px;\n' +
        '            margin: 25px;\n' +
        '        }\n' +
        '\n' +
        '        table {\n' +
        '            border: solid 1px black !important;\n' +
        '        }\n' +
        '\n' +
        '        .font-weight {\n' +
        '            font-weight: 600;\n' +
        '        }\n' +
        '\n' +
        '        thead tr td {\n' +
        '            font-weight: 600;\n' +
        '        }\n' +
        '\n' +
        '        td {\n' +
        '            padding: 5px !important;\n' +
        '            text-align: center !important;\n' +
        '        }\n' +
        '\n' +
        '        .border {\n' +
        '            border: solid 1px black !important;\n' +
        '        }\n' +
        '\n' +
        '        .border-left {\n' +
        '            border-left: solid 1px black !important;\n' +
        '            padding-left: 20px;\n' +
        '        }\n' +
        '\n' +
        '        .table{\n' +
        '            width: 100%;\n' +
        '        }\n' +
        '        .table-bordered td {\n' +
        '            /* border: solid 1px black; */\n' +
        '        }\n' +
        '    </style>\n' +
        '</head>\n' +
        '\n' +
        '<body style="background-color: lightgrey;">\n' +
        '    <div class="card" style="">\n' +
        '        <div class="card-body">\n' +
        '            <h1 style="color: #e26812; text-align: center; font-weight: 600; font-family: sans-serif; margin: 20px;">WE\n' +
        '                THE CHEFS</h1>\n' +
        '\n' +
        '            <img src="https://wethechefs.in/assets/images/ctc_img/Logo.png"\n' +
        '                style="position: absolute; top: 30px; right: 40px; width: 100px; height: 100px;" alt="">\n' +
        '            <hr>\n' +
        '\n' +
        '            <div class="" style="display: flex; padding: 50px;">\n' +
        '                <div class="" style="width: 45%; height: auto;">\n' +
        '                    <div class="" style="display: flex;">\n' +
        '                        <div class="" style="width: 40%; height: auto;">\n' +
        '                            <p>Invoice Number :</p>\n' +
        '                        </div>\n' +
        '                        <div class="" style="border: solid 1px black; width: 45%; height: auto;">\n' +
        '                            <p>' + this.pdfOrder.invoice_number + '</p>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                    <div class="" style="display: flex;">\n' +
        '                        <div class="" style="width: 40%; height: auto;">\n' +
        '                            <p>Order Number :</p>\n' +
        '                        </div>\n' +
        '                        <div class="" style="border: solid 1px black; width: 45%; height: auto;">\n' +
        '                            <p>' + this.pdfOrder.uid + '</p>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                    <div class="" style="display: flex;">\n' +
        '                        <div class="" style="width: 40%; height: auto;">\n' +
        '                            <p>Invoice Date :</p>\n' +
        '                        </div>\n' +
        '                        <div class="" style="border: solid 1px black; width: 45%; height: auto;">\n' +
        '                            <p>' + this.utilService.getDateTimeByTime(this.pdfOrder.created_at) + '</p>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                    <div class="" style="display: flex;">\n' +
        '                        <div class="" style="width: 40%; height: auto;">\n' +
        '                            <p>Customer Name :</p>\n' +
        '                        </div>\n' +
        '                        <div class="" style="border: solid 1px black; width: 45%; height: auto;">\n' +
        '                            <p>' + this.pdfOrder.customer_detail.name + '</p>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                    <div class="" style="display: flex;">\n' +
        '                        <div class="" style="width: 40%; height: auto;">\n' +
        '                            <p>Customer Phone :</p>\n' +
        '                        </div>\n' +
        '                        <div class="" style="border: solid 1px black; width: 45%; height: auto;">\n' +
        '                            <p>' + this.pdfOrder.customer_detail.phone + '</p>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '                <div class="" style="width: 10%; height: auto;">\n' +
        '                </div>\n' +
        '                <div class="" style="width: 45%; height: auto;">\n' +
        '                    <div class="" style="display: flex;">\n' +
        '                        <div class="" style="width: 60%; height: auto;">\n' +
        '                            <p>Delivery Address :</p>\n' +
        '                        </div>\n' +
        '                        <div class="" style="border: solid 1px black; width: 45%; height: auto;">\n' +
        '                            <p>' + this.pdfOrder.order_address.address + '</p>\n' +
        '                        </div>\n' +
        '                    </div>\n' +
        '                </div>\n' +
        '            </div>\n' +
        '\n' +
        '            <br>\n' +
        '            \n' +
        '            <table class="table table-bordered" border="1" cellpadding="0" cellspacing="0">\n' +
        '                <thead style="background-color: lightgrey;">\n' +
        '                    <tr>\n' +
        '                         <td rowspan="1">S.No</td>\n' +
        '                        <td rowspan="1">Item Name</td>\n' +
        '                        <td rowspan="1">Qty</td>\n' +
        '                        <td rowspan="1">Unit Rate</td>\n' +
        '                        <td rowspan="1">Taxable Amount</td>\n' +
        '                        <td colspan="2">IGST</td>\n' +
        '                        <td colspan="2">CGST</td>\n' +
        '                        <td colspan="2">SGST</td>\n' +
        '                        <td rowspan="1">Grand Total</td>\n' +
        '                    </tr>\n' +
        '                </thead>\n' +
        '                <tbody>\n' +
        '                    <tr>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td class="font-weight">%</td>\n' +
        '                        <td class="font-weight">Amount</td>\n' +
        '                        <td class="font-weight">%</td>\n' +
        '                        <td class="font-weight">Amount</td>\n' +
        '                        <td class="font-weight">%</td>\n' +
        '                        <td class="font-weight">Amount</td>\n' +
        '                        <td></td>\n' +
        '                    </tr>\n' +


        orderTr +


        '\n' +
        '                    <tr>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                    </tr>\n' +
        '\n' +
        orderTotalTr
        +
        '\n' +
        '                    <tr>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                    </tr>\n' +
        '\n' +
        '                    <tr class="font-weight">\n' +
        '                        <td></td>\n' +
        '                        <td>Delivery Charges</td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td>' + this.pdfOrder.delivery_charges + '</td>\n' +
        '                        <td>' + this.pdfOrder.deliveryIgstPer + '</td>\n' +
        '                        <td>' + this.pdfOrder.deliveryIgst + '</td>\n' +
        '                        <td>' + this.pdfOrder.deliverySGSTPer + '</td>\n' +
        '                        <td>' + this.pdfOrder.deliverySGST + '</td>\n' +
        '                        <td>' + this.pdfOrder.deliverySGSTPer + '</td>\n' +
        '                        <td>' + this.pdfOrder.deliverySGST + '</td>\n' +
        '                        <td>' + (this.pdfOrder.delivery_charges + this.pdfOrder.delivery_service_tax) + '</td>\n' +
        '                    </tr>\n' +
        '\n' +
        '                    <tr>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                        <td></td>\n' +
        '                    </tr>\n' +
        '\n' +
        orderFinalTotal
        +
        '                </tbody>\n' +
        '            </table>\n' +
        '\n' +
        '            <br>\n' +
        '\n' +
        '            <h3 class="" style="text-align: center; font-weight: 600; font-family: sans-serif; margin: 20px;">Unit of WETHECHEFS FOOD SOLUTIONS (OPC) PRIVATE LIMITED</h3>\n' +
        '            <div class="" style="display: flex; border: solid 1px black;">\n' +
        '                <div class="" style="width: 100px; margin-left: 10px;">Address</div>\n' +
        '                <div class="border-left">A 2229, Ground floor, Greenfields Colony, Faridabad, Haryana -121010</div>\n' +
        '            </div>\n' +
        '            <div class="" style="display: flex; border: solid 1px black;">\n' +
        '                <div class="col-2" style="width: 100px; margin-left: 10px;">Website</div>\n' +
        '                <div class="col border-left">www.WetheChefs</div>\n' +
        '\n' +
        '            </div>\n' +
        '            <div class="" style="display: flex; border: solid 1px black;">\n' +
        '                <div class="col-2" style="width: 100px; margin-left: 10px;">GSTIN : </div>\n' +
        '                <div class="col border-left">06AACCW9003E1ZL</div>\n' +
        '\n' +
        '            </div>\n' +
        '        </div>\n' +
        '    </div>\n' +
        '</body>\n' +
        '\n' +
        '<style>\n' +
        '</style>\n' +
        '\n' +
        '</html>';


      // console.log(html);

      //
      this.apiService.postAPI(this.apiService.BASE_URL + "common/sendEmailInvoice", {
        to: this.pdfOrder.customer_detail.email,
        subject: this.pdfOrder.invoice_number,
        content: html
        // content: btoa(html)
      }).then((result) => {
        // console.log("result:-" + JSON.stringify(result));
        this.pdfOrder = null;
      }, (error) => {
        // console.log("error:-" + JSON.stringify(error));
      })
    }, 500)

  }

  getActive(value){
    if(this.deliveryDT == ''){
      this.addDisabled = false
      this.toaster.error("Please Select Date and time");
    } 
    else {
      this.addDisabled = value
    }
  }
  get remainingAmount(): number {
    console.log(this.min_order_val,"deepak")
    return this.min_order_val - this.totalPrice; // Calculate remaining amount
  }
  getPaymentDisable(value){
    this.addPaymentDisabled = value;
  }
  // nextStep() {
  //   if (this.step < 3) {
  //     this.step++;
  //   }
  // }

  // previousStep() {
  //   if (this.step > 1) {
  //     this.step--;
  //   }
  // }
}

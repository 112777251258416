import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { APIService } from '../../services/api.service';
import { UtilService } from '../../services/util.service';
// import { SignupComponent } from '../auth/signup/signup.component';
// import { PrivacyPolicyComponent } from '../privacy-policy/privacy-policy.component';
import { TandcComponent } from '../tandc/tandc.component';
import {EmitEvent, Events, EventService} from "../../services/event.service";

@Component({
  selector: 'app-footer',
  templateUrl: './footer.component.html',
  styleUrls: ['./footer.component.scss']
})
export class FooterComponent implements OnInit {

  constructor(
    private router: Router,
    private modalService: NgbModal,
    private apiService: APIService,
    private utilService: UtilService,
    private eventService:EventService
  ) { }

  ngOnInit(): void {
  }

  openPrivacyPolicy(){
    this.router.navigate(['/privacy-policy'])
  }
  currentYear = new Date().getFullYear();

  opentermsandconditions(){
    const modalRef = this.modalService.open(TandcComponent, {
      size: 'xl',
      windowClass: "termsandconditions",
      centered: true});
    modalRef.componentInstance.name = 'World';
  }

  // openFAQ() {
  //   this.eventService.emit(new EmitEvent(Events.FAQ, ''));
  // }
  // openMeetChefs() {
  //   this.eventService.emit(new EmitEvent(Events.MEET_CHEFS, ''));
  // }
  // openHowItWorks() {
  //   this.eventService.emit(new EmitEvent(Events.PLANYOURPLATE, ''));
  // }
  openSameChefDay(){
    window.location.href = "https://www.wethechefs.in/chefdetail/Z-Appetite"
  }
  openBlog(){
    window.location.href ="https://blog.wethechefs.in/"
  }
  openCurated(){
window.location.href="https://events.wethechefs.in/"
  }

}

import { Component, OnInit, Inject,OnDestroy, PLATFORM_ID } from '@angular/core';
import { Event as NavigationEvent, NavigationStart, Router } from '@angular/router';
import { NgbModal } from '@ng-bootstrap/ng-bootstrap';
import { LoginComponent } from '../auth/login/login.component';
import { CartComponent } from '../cart/cart.component';
import { APIService } from "../../services/api.service";
import { UtilService } from "../../services/util.service";
import { EmitEvent, Events, EventService } from "../../services/event.service";
import { SignupComponent } from '../auth/signup/signup.component';
import { isPlatformBrowser, isPlatformServer } from '@angular/common';
import {CouponPageComponent} from "../../modals/coupon-page/coupon-page.component"
declare var $;
@Component({
  selector: 'app-navbar',
  templateUrl: './navbar.component.html',
  styleUrls: ['./navbar.component.scss']
})
export class NavbarComponent implements OnInit {
  closeResult = '';
  profile_pic = '/assets/images/default_profile_pic.png';
  url="/home"
  isLoggedIn = false;
  cart_items = 0;
  cart_items_quantity = 0;
  home_route: boolean = false;
  isLoginSelected = false;
  isSignupSelected = false;
  event$
  message;
  title;
  toggle: boolean = true;
  private intervalId: any; // to keep track of the interval
  private readonly popupDelay = 60 * 1000; // 1 minute in milliseconds
  constructor(
    private router: Router,
    private modalService: NgbModal,
    private apiService: APIService,
    private utilService: UtilService,
    private eventService: EventService,
    @Inject(PLATFORM_ID) private platformId: Object
  ) {
    this.event$
      = this.router.events
        .subscribe(
          (event: NavigationEvent) => {
            if (event instanceof NavigationStart) {
              // console.log(event.url);
              if (event.url == '/home' || event.url == '/') {
                this.home_route = true;
                // alert('hi from home')
              } else {
                this.home_route = false;
              }
            }
          });

    this.utilService.SharingData.subscribe((value) => {
      if (value == false) {
        this.logout();
      }
    })
    this.event$
    = this.router.events
      .subscribe(
        (event: NavigationEvent) => {
          if (event instanceof NavigationStart) {
            // console.log(event.url);
            if (!event.url.includes('/chefdetail')) {
              localStorage.removeItem('itemId');
            }
          }
        });
    const currentRoute = window.location.pathname;
        console.log(window.location,"deepak");
        
      
        if (!currentRoute.includes('/chefdetail')) {
      localStorage.removeItem('itemId');
      }
  }

  login() {
    // console.log("on login modal");
    const modalRef = this.modalService.open(LoginComponent, { size: '' });
    modalRef.dismissed.subscribe((result) => {
      console.log("dismissed")
    })
    modalRef.closed.subscribe((result) => {
      console.log("closed")
    })
  }

  openCustomerProfile(value) {
    this.utilService.setItem('customerprofile', value);
    this.utilService.SharingData.next(value);
    this.router.navigateByUrl('/customer-profile');
    // alert(value);
  }

  subscription;
  events = [];

  ngOnInit(): void {

    // $(document).ready(function() {
    //   var header = $(".navbar");
    
    //   var scrollThreshold = 50; 
    
    //   function updateHeaderBackground() {
    //     var scrollTop = $(window).scrollTop();
    //     if (scrollTop > scrollThreshold) {
    //       header.removeClass("bg-transparent").addClass("bg-solid");
    //     } else {
    //       header.removeClass("bg-solid").addClass("bg-transparent");
    //     }
    //   }
    
    //   updateHeaderBackground();
    //   $(window).scroll(updateHeaderBackground);
    // });


    this.startPopupTimer();
    // this.checkFirstTimeDiscountPopup();
    if (this.utilService.getItem(this.utilService.USER_LOGIN) == '1') {
      this.isLoggedIn = true;
    } else {
      this.isLoggedIn = false;
    }

    this.getCartItems()

    this.subscription = this.eventService.on(Events.USER_SIGNUP, (data => {
      if (this.utilService.getItem(this.utilService.USER_LOGIN) == '1') {
        this.isLoggedIn = true;
        let cart = this.utilService.getLocalCart()
        if (cart.length > 0 && this.utilService.getItem('replace_item_called') != "1") {
          this.utilService.setItem("replace_item_called", "1");
          this.replaceCartItems(cart)
        }
      }
    }));
    this.subscription = this.eventService.on(Events.CART_ITEM_UPDATE, (data => {
      // if (this.utilService.getItem(this.utilService.USER_LOGIN) == '1') {
      //  getcart items count
      // console.log("cart item update:-");
      this.getCartItems()
      // }
    }));
    this.subscription = this.eventService.on(Events.OPEN_NAVBAR_LOGIN, (data => {
      // if (this.utilService.getItem(this.utilService.USER_LOGIN) == '1') {
      //  getcart items count
      this.openauthmodal()
      // }
    }));
    this.apiService.getAPI(this.apiService.BASE_URL + "events/getAllEvents").then((result) => {
      if (result.status) {
        this.events = result.result;
      }
    }, (error) => {
      console.log("error");
    })
    if (isPlatformBrowser(this.platformId)) {
    if (window.location.pathname == '/home' || window.location.pathname == '/') {
      this.home_route = true;
    }
  }
  this.getBroadMessage();
  }
  ngOnDestroy(): void {
    if (this.intervalId) {
      clearInterval(this.intervalId);
    }
  }

  startPopupTimer(): void {
    this.intervalId = setInterval(() => {
      const hasSeenPopup = localStorage.getItem('hasSeenDiscountPopup2') === 'true';
    
    if (this.cart_items === 0 && !hasSeenPopup) {
        localStorage.setItem('hasSeenDiscountPopup2', 'true')
        this.showPopup();
      }
    }, this.popupDelay);
  }

  showPopup(): void {
    const modalRef = this.modalService.open(CouponPageComponent, { size: '', windowClass: 'mobileClass', centered: true });
    modalRef.dismissed.subscribe(() => {
      console.log("Popup dismissed");
    });
  }

    // checkFirstTimeDiscountPopup(): void {
    //   const hasSeenDiscount = localStorage.getItem('hasSeenDiscountPopup');
    //   if (!hasSeenDiscount) {
    //     this.openDiscountPopup();
      
    //   }
     
    // }
  getCartItems() {
    if (this.utilService.isUserLoggedIn()) {
      this.apiService.getAPI(this.apiService.BASE_URL + "cart/getCartItemDetailByUserId/" + this.utilService.getUserID()).then((result) => {
        if (result.status) {
          this.cart_items = result.result.length;
          this.cart_items_quantity = 0;
          for (let i = 0; i < result.result.length; i++) {
            this.cart_items_quantity += result.result[i].quantity;
          }
          // console.log("cart Items:-" + JSON.stringify(result.result, null, 4));
        } else {
          this.cart_items = 0;
          this.cart_items_quantity = 0;
        }
      }, (error) => {
        console.log("error");
        this.cart_items = 0;
        this.cart_items_quantity = 0;
      })
    } else {
      let localCart = this.utilService.getLocalCart();
      // console.log("cart Items:-" + JSON.stringify(localCart, null, 4));
      // console.log("length:-" + localCart.length);
      this.cart_items = localCart.length;
      this.cart_items_quantity = 0;
      for (let i = 0; i < localCart.length; i++) {
        this.cart_items_quantity += localCart[i].quantity;
      }
    }
  }

  logout() {
    this.utilService.setItem(this.utilService.USER_LOGIN, "0");
    let cart = [];
    this.utilService.setItem(this.utilService.LOCAL_CART, JSON.stringify(cart));
    this.utilService.setItem("replace_item_called", "0");
    this.isLoggedIn = false;
    if (this.home_route == true) {
    window.location.reload()
    // this.router.navigateByUrl('/')}
    }else {
      this.router.navigateByUrl('/home')
    }
    this.cart_items = 0;
    this.cart_items_quantity = 0;
  }

  opencart() {
    // if (this.utilService.isUserLoggedIn()) {
    const modalRef = this.modalService.open(CartComponent, {
      size: '',
      windowClass: "mobileClass",
      centered: true
    });
    // }
  }

  openauthmodal() {
    // this.router.navigateByUrl("/test");
    // console.log("login modal");
    const modalRef = this.modalService.open(LoginComponent, {
      windowClass: "mobileClass",
      centered: true
    });

    modalRef.dismissed.subscribe((result) => {
      console.log("dismissed")
      if (this.utilService.getItem(this.utilService.USER_LOGIN) == '1') {
        this.isLoggedIn = true;
        // this.router.navigateByUrl('/home')
        this.getCartItems()
      }
    })
    modalRef.closed.subscribe((result) => {
      console.log("closed")
      if (this.utilService.getItem(this.utilService.USER_LOGIN) == '1') {
        this.isLoggedIn = true;
        let cart = this.utilService.getLocalCart()
        if (cart.length > 0 && this.utilService.getItem('replace_item_called') != "1") {
          this.utilService.setItem("replace_item_called", "1");
          this.replaceCartItems(cart)
        } else {
          // this.router.navigateByUrl('/home')
          this.getCartItems()
        }
      }
    })
  }
  opensignin() {
    const modalRef = this.modalService.open(SignupComponent, {
      windowClass: "mobileClass_signUp",
    });
    modalRef.closed.subscribe((result) => {
      console.log("closed")
    })
    modalRef.componentInstance.type = 'customer';
    modalRef.componentInstance.orignFrom = 'customer';
  }
  // closeModal() {
  //   this.activeModal.close();
  // }
  replaceCartItems(items) {
    this.apiService.postAPI(this.apiService.BASE_URL + "cart/replaceCartItems", {
      items: items,
      user_id: this.utilService.getUserID()
    }).then((result) => {
      this.utilService.deleteFullLocalCart()
      if (result.status) {
        this.getCartItems()
      }
    }, (error) => {
      console.log("error");
      this.cart_items = 0;
      this.cart_items_quantity = 0;
    })
  }

  openEvent(id: any) {
    // this.utilService.setItem('event_id', id);
    // this.router.navigateByUrl('/event-detail/' + id);

    this.router.navigate(
      ['/event-detail'],
      { queryParams: { event_id: id } }
    );

    // this.router.navigateByUrl('/coast-to-coast');

    this.eventService.emit(new EmitEvent(Events.EVENT_INITIALIZE, id));

  }
  openWhatsappp(){
    window.open("https://wa.me/918800299840?text=Hi",'_blank')
    // window.location.href="https://wa.me/918800299840"
  }
  
  change() {
    this.toggle = !this.toggle;
  }
  getBroadMessage(){
    this.apiService.getAPI(this.apiService.BASE_URL + "common/getbroadcastMessage").then((result)=>{

   if (result.status) {
          // this.toaster.success('Message sent');
          this.message = result.result.message;
          this.title = result.result.title;
          // console.log(this.title,"deepak");
          
      } 
  }, (error) => {
      console.log('error:-' + error.message);
  })
  }
  openCoupon() {
    const modalRef = this.modalService.open(CouponPageComponent, { size: '', windowClass: 'mobileClass', centered: true });
    modalRef.dismissed.subscribe((result) => {
      console.log("dismissed")
    })
  }
  openBroadcast(){
    window.location.href=this.title
  }
}
